import React from "react";
import { useState, useEffect } from "react";
//import "../pages/Pages.css";
import whatsappimage from '../svg/comfort.png'

const Comfortdatadeletionpolicy = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [reason, setReason] = useState("");

    useEffect(() => {
        document.title = "Comfort 360";
        document.getElementById("roots").href = whatsappimage;
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        setPhoneNumber("");
        setEmail("");
        setReason("");
        alert("Your data deletion request has been submitted.");
      };
    
  return (
    <>
      <h1 style={{ textAlign: "center" }}>Data Deletion Policy</h1>
      <ol type="1">
        <li>
          <h4>Purpose</h4>
          This document sets out our policy for responding to requests for
          deletion of data under India data protection law. This document explains
          the rights of the data subject in relation to data deletion and the
          responsibilities of Comfort 360 in responding with such a request.
        </li>
        <li>
          <h4>Individual Rights</h4>
          An individual has the right to erasure, also known as ‘the right to be
          forgotten’. The principle underpinning this right is to enable an
          individual to request the deletion or removal of personal data where
          there is no compelling reason for its continued processing.
        </li>
        <li>
          <h4>When does the right to erasure apply?</h4>
          As stipulated in India data protection law, individuals have a right to
          have personal data erased and to prevent processing in specific
          circumstances:
          <ul>
            <li>
              Where the personal data is no longer necessary in relation to the
              purpose for which it was originally collected/processed;
            </li>
            <li>When the individual withdraws consent;</li>
            <li>
              When the individual objects to the processing and there is no
              other legal ground for the relevant processing activity;
            </li>
            <li>When the personal data was unlawfully processed;</li>
            <li>
              Where the personal data has to be erased in order to comply with a
              legal obligation.
            </li>
          </ul>
        </li>
        <li>
          <h4>What information does Comfort 360 retain?</h4>
          Comfort 360 stores data about individuals in order to provide services related to furniture. We may store
          information such as name, email address, and vehicle preferences to enhance user experience and provide
          personalized services. This data is stored and used in accordance with our Privacy Policy, which can be found at:
          <a style={{color:"blue"}} href="https://networking.cognisun.net/comfortprivacy">Privacy policy</a>.<br /><br />
          If consent is provided, personal data may be stored for the purposes of contacting you regarding Comfort 360 news,
          products, and promotions.
        </li>
        <li>
          <h4>How can data be deleted?</h4>
          If you wish to delete your data from Comfort 360, please follow the steps below:
          <br /><br />
          <form onSubmit={handleSubmit}>
            <label htmlFor="phonenumber">Your phone number:</label><br />
            <input
              type="text"
              id="phonenumber"
              name="phonenumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            /><br />

            <label htmlFor="email">Your Email:</label><br />
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            /><br /><br />

            <label htmlFor="reason">Reason for Data Deletion:</label><br />
            <textarea
              id="reason"
              name="reason"
              rows="4"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              required
            ></textarea><br /><br />
            <input type="submit" value="Submit Request" />
          </form>
          <br />
          For data stored directly by Comfort 360:
          <ul>
            <li>
              Email your request to cognisunnotification@gmail.com with the subject line "Data Deletion Request".
            </li>
            <li>
              We will delete your data within one month (30 calendar days) of receiving your request.
            </li>
            <li>
              Once deleted, your data cannot be recovered.
            </li>
          </ul>
          <br />
          For data stored by third-party services or platforms, please refer to their respective policies and procedures for
          data deletion.
        </li>
      </ol>
    </>
  );
}

export default Comfortdatadeletionpolicy;
