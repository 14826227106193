import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import QRCode from "react-qr-code";
import "../App.css";
import bgImage from "../svg/waves.svg";
import email from "../svg/mail.svg";
import call from "../svg/call.svg";
import aboutme from "../svg/about-me.png";
import baboutme from "../svg/babout-me.png";
import product from "../svg/product.png";
import bproduct from "../svg/bproduct.png";
import businessname from "../svg/businessname.png";
import bbusinessname from "../svg/bbusinessname.png";
import whatsapp from "../svg/whatsapp.png";
import bwhatsapp from "../svg/bwhatsapp.png";
import linkedin from "../svg/linkedin.svg";
import location from "../svg/location.svg";
import website from "../svg/website.svg";
import facebook from "../svg/facebook.png";
import bfacebook from "../svg/bfacebook.png";
import instagram from "../svg/instagram.png";
import binstagram from "../svg/binstagram.png";
import businesscard from "../svg/businesscard.svg";
import logoPlaceholder from "../svg/logo-placeholder.svg";
import userPlaceholder from "../svg/user-placeholder.svg";
import BbgImage from "../svg/Bwaves.svg";
import Bemail from "../svg/Bmail.svg";
import Bcall from "../svg/Bcall.svg";
import Blinkedin from "../svg/Blinkedin.svg";
import Blocation from "../svg/Blocation.svg";
import Bwebsite from "../svg/Bwebsite.svg";
import BlogoPlaceholder from "../svg/Blogo-placeholder.svg";

//const apiUrl = "https://networking.cognisun.net:5681/"; //ProdRMB
//const apiUrl = "https://businesspositive.cognisun.net:4054/"; // PROD
//const apiUrl = 'https://rmb.cognisunsandbox.com:4014/';       // UAT
// router
// const apiUrl = "https://app.aibn360.com:5703/"; //AiBN Prod

//const apiUrl = "https://rmb.cognisunsandbox.com:4014/" // NetworkSSO

const apiUrl = "https://networking.cognisun.net:4015/"; // NetworkSSOProd

//const apiUrl = 'http://localhost:4052/api/'  // Local

export const Users = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [profilePicture, setProfilePicture] = useState([]);
  const [companyLogo, setCompanyLogo] = useState([]);
  const [qrCodeData, setQrCodeData] = useState(null);
  // const params = useParams();
  // let personid = params.userId;

  // console.log("queryParams",personid);
  useEffect(() => {
    getMemberDetail();
    generateVCard();
  }, []);

  const getMemberDetail = () => {
    fetch(`${apiUrl}member/getMemberDetails`, {
      method: "POST",
      body: JSON.stringify({
        personid: window.location.pathname.split("/").slice(-1)[0],
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        //   setData(res.data[0]);
        //   console.log("daaaa", res.data[0].organizationtype);
        //   console.log("Hiiiii", data.organizationtype);
        //   setProfilePicture(apiUrl + res.data[0].profilepicture);
        //   setCompanyLogo(apiUrl + res.data[0].logo);
        //   localStorage.setItem("CardData", JSON.stringify(res.data[0]));
        //   setTimeout(() => {
        //     setSpinner(false);
        //   }, 1000);
        // })

        if (res.data[0]) {
          // Data is not null, update the states
          setData(res.data[0]);
          setProfilePicture(apiUrl + res.data[0].profilepicture);
          setCompanyLogo(apiUrl + res.data[0].logo);
          setTimeout(() => {
            setSpinner(false);
          }, 1000);
        } else {
          // Data is null, redirect to PageNotFound

          navigate("*"); // You can use the appropriate function to redirect in your framework (e.g., navigate in React Router)
        }
      })
      .catch((err) => {
      });
  };
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    // document.title =
    //   data.firstname + " " + data.lastname + "'s" + " " + "VCard";
    document.getElementById("roots").href = profilePicture;
  }, [data.firstname, profilePicture]);

  let CardData = JSON.parse(localStorage.getItem("CardData"));
  // console.log("CardData", document.getElementById("roots").href);
  // const handleClick = () => {
  // 	if (navigator.share) {
  // 	  navigator.share({
  // 		title: 'QR Code',
  // 		text: 'Check out this QR code',
  // 		url: 'https://www.example.com'
  // 	  })
  // 		.then(() => console.log('Sharing was successful.'))
  // 		.catch((error) => console.error('Sharing failed:', error));
  // 	} else {
  // 	  console.error('Web Share API is not supported by this browser.');
  // 	}
  //   };
  const generateVCard = () => {
    const vcard = [
      "BEGIN:VCARD",
      "VERSION:4.0",
      `FN:${data.firstname} ${data.lastname}`,
      `TEL;TYPE=WORK,VOICE:${"+" + data.countrycode + data.phonenum}`,
      `EMAIL;TYPE=PREF,INTERNET:${data.email}`,
      `ORG:${data.businessname}`,
      `URL;TYPE=LINKEDIN${data.linkdlnlink}`,
      `URL;TYPE=WEBSITE${data.website}`,
      `URL;TYPE=WEBSITE${data.website}`,
      "END:VCARD",
    ].join("\n");

    const blob = new Blob([vcard], { type: "text/vcard;charset=utf-8" });
    const vCardURL = URL.createObjectURL(blob);
    setQrCodeData(vCardURL);
    // saveAs(blob, `${data.firstname}_${data.lastname}.vcf`);
  };
  const generateWebURL = (url) => {
    if (!url.startsWith("http")) {
      window.open(`http://${url}`, "_blank");
    } else if (url.startsWith("https")) {
      window.open(`${url}`, "_blank");
    } else if (url.startsWith("http:")) {
      window.open(`${url}`, "_blank");
    } else {
      window.open(`https://${url}`, "_blank");
    }
  };

  return spinner ? (
    <div className="spinner-wrap">
      <ClipLoader color="#3366FF" size={90} border-width={5} />
    </div>
  ) : (
    <div className="App">
      {/* <Helmet>
        <title>{`${data.firstname} ${data.lastname}'s VCard`}</title>
        <meta name="title"  content={`${data.firstname} ${data.lastname}'s VCard`} />
        <meta name="description"  content={`${data.occupation} ${data.businessname}`} />
        <meta name="roots"  content={data.profilepicture ? profilePicture : userPlaceholder} />
        {/* <meta property="og:title" content={`${data.firstname} ${data.lastname}'s VCard`} />
        <meta property="og:description" content={`${data.occupation} ${data.businessname}`} />
        <meta property="og:roots" content={data.profilepicture ? profilePicture : userPlaceholder} /> */}
        {/* <meta property="og:url" content={window.location.href} /> */}
        {/* <meta property="og:type" content="profile" />
      </Helmet> */} 
      <div className="main-wrap">
        <div className="innercard-wrap">
          <div className="person-image">
            <img
              src={data.profilepicture ? profilePicture : userPlaceholder}
              alt="Loading Image"
            />
            <div className="waves-bg">
              <img
                src={
                  data.organizationtype != "BusinessPositive Chapter"
                    ? bgImage
                    : BbgImage
                }
                alt="BG"
              ></img>
              <div className="logo-image">
                <img
                  src={
                    data.logo
                      ? companyLogo
                      : data.organizationtype != "BusinessPositive Chapter"
                        ? logoPlaceholder
                        : BlogoPlaceholder
                  }
                  alt="Loading Image"
                />
              </div>
            </div>
          </div>
          <div className="person-details">
            <div className="person-details-inner">
              <h3>{data.firstname + " " + data.lastname} </h3>
              <p className="Cname">{data.occupation}</p>
              <p className="Cname">{data.businessname}</p>
            </div>
          </div>

          <div className="person-contacts">
            <div className="cont_header">Contact</div>
            <div className="contact_head">
              <div className="first_contact">
                <div className="icon_widget">
                  {data.phonenum ? (
                    <button
                      className="call"
                      onClick={() => {
                        window.location.href = `tel:${data.phonenum}`;
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? call
                            : Bcall
                        }
                        alt="BG"
                      ></img>
                    </button>
                  ) : null}
                  {data.phonenum ? (
                    <span className="icon_text">Call</span>
                  ) : null}
                </div>

                <div className="icon_widget">
                  {data.phonenum ? (
                    <button
                      className="call"
                      onClick={() => {
                        window.location.href = `//api.whatsapp.com/send?phone=${data.ccode + data.phone
                          }&text=Hello`;
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? whatsapp
                            : bwhatsapp
                        }
                        alt="BG"
                      ></img>
                    </button>
                  ) : null}
                  {data.phonenum ? (
                    <span className="icon_text">WhatsApp</span>
                  ) : null}
                </div>
                <div className="icon_widget">
                  {data.email ? (
                    <button
                      classname="email"
                      onClick={() => {
                        window.open(
                          `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${data.email}`,
                          "_blank"
                        );
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? email
                            : Bemail
                        }
                        alt="Email"
                      ></img>
                    </button>
                  ) : null}
                  {data.email ? <span className="icon_text">Email</span> : null}
                </div>
              </div>
            </div>
          </div>

          {/* Profile */}
          <div className="person-contacts">
            <div className="cont_header">Profile</div>
            <div className="contact_head">
              <div className="first_contact">
                <div className="icon_widget">
                  {data.linkdlnlink ? (
                    <button
                      className="linkedin"
                      onClick={() => {
                        window.open(`${data.linkdlnlink}`, "_blank");
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? linkedin
                            : Blinkedin
                        }
                        alt="Linkedin"
                      ></img>
                    </button>
                  ) : null}
                  {data.linkdlnlink ? (
                    <span className="icon_text">LinkedIn</span>
                  ) : null}
                </div>


                <div className="icon_widget">
                  {data.facebooklink ? (
                    <button
                      className="linkedin"
                      onClick={() => {
                        window.open(`${data.facebooklink}`, "_blank");
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? facebook
                            : bfacebook
                        }
                        alt="Linkedin"
                      ></img>
                    </button>
                  ) : null}
                  {data.facebooklink ? (
                    <span className="icon_text">Facebook</span>
                  ) : null}
                </div>

                <div className="icon_widget">
                  {data.instalink ? (
                    <button
                      className="address"
                      onClick={() => {
                        window.open(`${data.instalink}`, "_blank");
                      }}
                    >
                      <img
                        src={
                          data.organizationtype != "BusinessPositive Chapter"
                            ? instagram
                            : binstagram
                        }
                        alt="Location"
                      ></img>
                    </button>
                  ) : null}
                  {data.instalink ? (
                    <span className="icon_text">Instagram</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          {/* Other Info */}

          <div className="person-contacts">
            {data.aboutme ? (
              <button className="address" onClick={() => { }}>
                <img
                  src={
                    data.organizationtype != "BusinessPositive Chapter"
                      ? aboutme
                      : baboutme
                  }
                  alt="Location"
                ></img>
                <span>{data.aboutme}</span>
              </button>
            ) : null}

            {data.businessname ? (
              <button className="address" onClick={() => { }}>
                <img
                  src={
                    data.organizationtype != "BusinessPositive Chapter"
                      ? businessname
                      : bbusinessname
                  }
                  alt="Location"
                ></img>
                <span>{data.businessname}</span>
              </button>
            ) : null}

            {data.productservices ? (
              <button className="address" onClick={() => { }}>
                <img
                  src={
                    data.organizationtype != "BusinessPositive Chapter"
                      ? product
                      : bproduct
                  }
                  alt="Location"
                ></img>
                <span>{data.productservices}</span>
              </button>
            ) : null}
            {data.fullAddress ? (
              <button className="address" onClick={() => { }}>
                <img
                  src={
                    data.organizationtype != "BusinessPositive Chapter"
                      ? location
                      : Blocation
                  }
                  alt="Location"
                ></img>
                <span>{data.fullAddress}</span>
              </button>
            ) : null}
            {data.website ? (
              <button className="address" onClick={() => {
                generateWebURL(data.website);
              }}>
                <img
                  src={
                    data.organizationtype != "BusinessPositive Chapter"
                      ? website
                      : Bwebsite
                  }
                  alt="Location"
                ></img>
                <span>{data.website}</span>
              </button>
            ) : null}
          </div>
          <center>
            {/* <QRCode  onScan={generateVCard} /> */}
            {/* <QRCode value="https://www.example.com" /> */}
            <QRCode
              style={{
                width: 220,
                height: 220,
                marginTop: 30,
                marginBottom: 30,
              }}
              value={`BEGIN:VCARD\nVERSION:4.0\nFN:${data.firstname} ${data.lastname
                }\nTEL;TYPE=WORK,VOICE:${"+" + data.countrycode + data.phonenum
                }\nEMAIL;TYPE=PREF,INTERNET:${data.email}\nORG:${data.businessname
                }\nTITLE:${data.occupation}\nURL;TYPE=LINKEDIN:${data.linkdlnlink
                }\nURL;TYPE=WEBSITE:${data.website}\nEND:VCARD`}
            />
          </center>
        </div>
      </div>
    </div>
  );
};
