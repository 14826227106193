import React from "react";
import { useState, useEffect } from "react";
import "../pages/Pages.css";
import applogo from '../svg/appstore-logo.svg'
import phonologo from '../svg/CognisunMain.png';
import cognisunLogo from '../svg/cognisunLogo.png'

const Cognisun = () => {
    useEffect(() => {
        document.title = "Cognisun";
        document.getElementById("roots").href = cognisunLogo;
    }, []);

    return (
        <>
            <div className="wraper">
                <div className="left-screen">
                    <div className="header">
                        <div className="logo">
                            <img src={cognisunLogo} />
                        </div>
                        <div className="nav">
                            <div className="text-wrapper">Home</div>
                            <div className="text-wrapper">Contact</div>
                            <div className="text-wrapper">Features</div>
                            <div className="text-wrapper">About</div>
                            <div className="text-wrapper">Events</div>
                        </div>
                    </div>
                    <div className="contaner">
                        <h1 className="text-header">
                            Be the hero of your own success story with{" "}
                            <span className="header-para">“Cognisun Network”</span>
                        </h1>
                        <p className="para-text">
                            At Cognisun Networking, we empower you to be the hero of your own
                            success story. With our platform, you can connect with
                            professionals, access valuable resources, and unlock new
                            opportunities to achieve your goals. Join us today and start your
                            journey towards success.
                        </p>
                        {/* <span className="text-wrapper-21"></span> */}
                    </div>
                </div>
                <div className="right-screen">
                    <div className="download-btn">
                        <button className="btn-down"> Download Now</button>
                    </div>
                    <div className="pone-img">
                        <img src={phonologo} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Cognisun;
