import { useState, useEffect } from "react";
// import "../App.css";
//import IdDance from "../svg/IdNotFound.gif"
// import IdDance from "../svg/sad.png"
import Cognisun from "./Cognisun";
export const HomePage = () => {


  return (
    <div className="home">
      <Cognisun />
    </div>
  )
}


