import React from "react";
import { useState, useEffect } from "react";
import "../pages/Pages.css";
import applogo from '../svg/appstore-logo.svg'
import whatsappimage from '../svg/RmbLogo.png'
import phonologo from '../svg/mockup-3.png';
import googleplay from '../svg/rectange.png'

const Rmb = () => {

    const appClick = () => {
        window.open("https://apps.apple.com/in/app/rmb-network/id1577716909");
    }
    const playClick = () => {
        window.open("https://play.google.com/store/apps/details?id=com.lbn_flutter_project");
    }

    useEffect(() => {
        document.title = "RMB";
        document.getElementById("roots").href = whatsappimage;
    }, []);


    return (
        <>
            <div className="wraper">
                <div className="left-screen">
                    <div className="header">
                        <div className="logo">
                            <img src={whatsappimage} />
                        </div>
                        <div className="nav">
                            <div className="text-wrapper">Home</div>
                            <div className="text-wrapper">Contact</div>
                            <div className="text-wrapper">Features</div>
                            <div className="text-wrapper">About</div>
                            <div className="text-wrapper">Events</div>
                        </div>
                    </div>
                    <div className="contaner">
                        <h1 className="text-header">
                            Be the hero of your own success story with{" "}
                            <span className="header-para-RMB">“RMB Network”</span>
                        </h1>
                        <p className="para-text">
                            At RMB Networking, we empower you to be the hero of your own
                            success story. With our platform, you can connect with
                            professionals, access valuable resources, and unlock new
                            opportunities to achieve your goals. Join us today and start your
                            journey towards success.
                        </p>
                        {/* <span className="text-wrapper-21"></span> */}
                    </div>

                    <div>
                        <img className="googleplay" src={applogo} onClick={appClick} />
                        <img className="googleplay" src={googleplay} onClick={playClick} />
                    </div>

                </div>
                <div className="right-screen">
                    <div className="download-btn">
                        <button className="btn-down-RMB"> Download Now</button>
                    </div>
                    <div className="pone-img">
                        <img src={phonologo} />
                    </div>
                </div>
            </div>
        </>
    );

};
export default Rmb;
