import React, {useEffect , useState } from "react";
import "../App.css";
import konnectlogo from "../svg/Konnect_logo.png";
const BulkUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  const apiUrl = "https://rmb.cognisunsandbox.com:4014/"; // Local
  //"http://localhost:4053/api/"
  //"https://rmb.cognisunsandbox.com:4014/"

  const selectFile = (event) => {
    setShowAlert(false);
    if (event.target.files && event.target.files[0]) {
      let docname = event.target.files[0].name;
      setFileName(docname);
      setSelectedFile(event.target.files[0]);
    } else {
      setSelectedFile(null);
      setShowAlert(true);
    }
  };

  useEffect(() => {
    document.title = "Konnect";
    document.getElementById("roots").href = konnectlogo;
}, []);

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const uploadExcel = async () => {
    if (selectedFile) {
      setShowAlert(false);
      const data = new FormData();
      data.append("file", selectedFile);
      try {
        const response = await fetch(`${apiUrl}member/bulkdataimport`, {
          method: "POST",
          body: data,
          headers: {
            token: textInput,
          },
        });
        const result = await response.json();
        // console.log('API response:', result);
        if (result.success) {
          setIsSuccess(true);
        } else {
          setIsFailed(true);
          setErrorData(result.data[0]);
        }
        setSelectedFile(null);
      } catch (err) {
        console.error("Excel upload failed:", err);
        setIsFailed(true);
        setSelectedFile(null);
      }
    }
  };

  return (
    <div className="excel-upload">
      <img height={120} src={konnectlogo} />
      <h2 style={{ marginTop: 15 }}>Upload Excel File</h2>
      <div id="inputs">
        <input type="file" onChange={selectFile} accept=".csv" />
        <input
          className="input"
          type="text"
          value={textInput}
          onChange={handleTextInputChange}
          placeholder="Enter Token"
        />
      </div>
      <div className="d-flex gap-3">
        <button onClick={uploadExcel}>Upload</button>
        <a href="https://networking.cognisun.net/NetworkSSOProd/document/OtherDocs/KonnectBulkImport.csv">
          <button>Download Excel</button>
        </a>
      </div>
      {isLoading && <div className="spinner-border text-success mt-3"></div>}
      <div className="container">
        {isSuccess && <h2 className="text-success">Upload Success</h2>}
        {(isFailed || (errorData && errorData.length > 0)) && (
          <h2 className="text-danger">Upload failed</h2>
        )}
        {errorData && errorData.length > 0 && (
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Errors</th>
              </tr>
            </thead>
            <tbody>
              {errorData.map((val) => (
                <tr key={val.MemberBulkImportHeaderId}>
                  <td>{val.FirstName}</td>
                  <td>{val.Mobile}</td>
                  <td>{val.ErrorMsg}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
export default BulkUpload;
